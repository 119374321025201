import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SeachWithModal from "./component";
import { reloadCarList } from "../car-listing/actions";
import { DEFAULT_AE_CITY_CODE, DEFAULT_AE_CITY_NAME } from "../../../constants/app-constants";
import { setSearchResultTrackingData } from "../filters/actions";

const mapStateToProps = ({
    filters: {
        allFilters,
        appliedSuggestions,
        urlOptions
    },
    cities: {
        selectedCity: {
            code: selectedCityCode = DEFAULT_AE_CITY_CODE,
            name: selectedCityName = DEFAULT_AE_CITY_NAME
        } = {}
    }
}) => ({
    allFilters,
    appliedSuggestions,
    urlOptions,
    selectedCityName,
    selectedCityCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    setSearchResultTrackingDataConnect: setSearchResultTrackingData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SeachWithModal);
