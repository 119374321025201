import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Styles
import styles from "./styles.css";

import SearchIcon from "./images/search-icon";

// Components
import SearchWithModal from "../search-with-modal";

// Constants
import { ROUTE_NAME } from "../../../constants/routes-constants";

const HomeSearchSection = ({
    reloadCarListConnect
}) => {

    useEffect(() => {
        reloadCarListConnect(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div styleName={"styles.homeSearchSection"}>
            <span styleName={"styles.searchIcon"}><SearchIcon /></span>
            <SearchWithModal source={ROUTE_NAME.HOME} />
        </div>
    );
};

HomeSearchSection.propTypes = {
    selectedCityCode: PropTypes.string,
    selectedCityName: PropTypes.string,
    reloadCarListConnect: PropTypes.func
};

export default withRouter(HomeSearchSection);
