/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

// Styles
import styles from "./styles.css";

// Component
import Modal from "../../shared/modal";
import InputText from "../../shared/input-text";
import loadable from "@loadable/component";
import PageLoader from "../../shared/page-loader";
const MakeModalSearchInfo = loadable(() => import("../make-modal-search-info"), { fallback: <PageLoader /> });
import CrossIcon from "../../shared/cross-image";

// Constant
import { GA_EVENT_MAP } from "./constants";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";

//Utils
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
// import { NUMBER } from "../../../constants/app-constants";
import Filters from "../../../utils/filters-v2";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import { clearItem } from "../../../utils/helpers/storage-crud";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
// import { useCallback } from "react";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

const SearchWithModal = ({
    source,
    allFilters,
    selectedFilters,
    urlOptions,
    reloadCarListConnect,
    selectedCityName,
    selectedCityCode,
    setSearchResultTrackingDataConnect
}) => {
    const location = useLocation();
    const { customReplace } = useCustomHistoryOperations();

    const queryParams = parseLocationSearchParams(location.search);
    const [isSearchModal, setIsSearchModal] = useState(queryParams.source === "searchModel" ? true : false);
    const [searchTerm, setSearchTerm] = useState(queryParams.search);
    const inputRef = useRef(null);
    useEffect(() => {
        if (!isSearchModal) {
            inputRef.current.blur();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearchModal]);

    const toggelSearchBarModal = async () => {
        if (!isSearchModal && source) {
            trackPlatformCustomEventsAEV2(GA_EVENT_MAP[source]);
        }
        setIsSearchModal(!isSearchModal);
    };

    const onSearchRemove = async () => {
        setSearchTerm("");
        clearItem("searchQuery");
        await yieldToMainThread();
        setSearchResultTrackingDataConnect({});
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL } = filters.getListingPayload({ selectedFilters, filters: allFilters, options: urlOptions });
        await customReplace(relativeURL);
        reloadCarListConnect(true);
        scrollToTop();
    };

    // const [index, setIndex] = useState(0);
    // const [subIndex, setSubIndex] = useState(0);
    // const [blink, setBlink] = useState(true);
    // const [reverse, setReverse] = useState(false);

    // const handleSubText = useCallback(() => {
    //     setSubIndex((prev) => prev + (reverse ? -1 : 1));
    //   // eslint-disable-next-line no-nested-ternary
    //   }, [reverse]);

    // typeWriter
//   useEffect(() => {
//     let timeout;
//     if (window !== undefined) {
//         if (index === SEARCH_SUGGESTIONS.length - 1 && subIndex === SEARCH_SUGGESTIONS[index].length) {
//           return;
//         }

//         if (
//           subIndex === SEARCH_SUGGESTIONS[index].length + 1 &&
//           index !== SEARCH_SUGGESTIONS.length - 1 &&
//           !reverse
//         ) {
//           setReverse(true);
//           return;
//         }

//         if (subIndex === 0 && reverse) {
//           setReverse(false);
//           setIndex((prev) => prev + 1);
//           return;
//         }

//         // eslint-disable-next-line no-nested-ternary
//         timeout = window.setTimeout(handleSubText, Math.max(reverse ? NUMBER.SEVENTY : subIndex === SEARCH_SUGGESTIONS[index].length ? NUMBER.THOUSAND :
//                     NUMBER.ONE_HUNDRED_EIGHTY, parseInt(Math.random() * NUMBER.THREE_HUNDRED_SIXTY)));
//     }

//     // eslint-disable-next-line consistent-return
//     return () => {
//         if (window !== undefined && timeout) {
//             window.clearTimeout(timeout);
//         }
//     };
//   }, [subIndex, index, reverse, handleSubText]);

  // blinker
//   useEffect(() => {
//     if (window !== undefined && blink) {
//         if (index === (SEARCH_SUGGESTIONS.length - 1) && subIndex === SEARCH_SUGGESTIONS[SEARCH_SUGGESTIONS.length - 1].length) {
//             setBlink(false);
//         }
//     }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [index, subIndex]);
    return (
        <div styleName={"styles.searchWithModalWrapper"}>
            <InputText
                // placeholder={`${SEARCH_SUGGESTIONS[index].substring(0, subIndex)}${blink ? "|" : " "}`}
                placeholder={`Search by Brand or Model`}
                onFocusCallback={toggelSearchBarModal}
                inputRef={inputRef}
                value={searchTerm}
            />
            {/* {searchTerm && <span className={"closeSearchButtonFilter"}><CloseButton type="blue" onClickHandler={onSearchRemove} /></span>} */}
            {searchTerm && <span styleName={"styles.crossButton"}><CrossIcon onClick={onSearchRemove} /></span>}
            {isSearchModal && (
                <Modal isOpen={true} overlayClass="overlayClass">
                    <MakeModalSearchInfo
                        toggelSearchBarModal={toggelSearchBarModal}
                        source={source}
                        setSearch={setSearchTerm}
                        searchVal={searchTerm}
                    />
                </Modal>
            )}
        </div>
    );
};

SearchWithModal.propTypes = {
    location: PropTypes.object,
    source: PropTypes.string,
    placeholderText: PropTypes.string,
    allFilters: PropTypes.object,
    selectedFilters: PropTypes.array,
    urlOptions: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    selectedCityName: PropTypes.string,
    selectedCityCode: PropTypes.string,
    setSearchResultTrackingDataConnect: PropTypes.func
};

export default SearchWithModal;
