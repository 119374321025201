import { ROUTE_NAME } from "../../../constants/routes-constants";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";

export const GA_EVENT_MAP = {
    [ROUTE_NAME.HOME]: b2cHomePageEvents.homePageSearch,
    [ROUTE_NAME.LISTING]: b2cListingPageEvents.listingSearchClick
};

export const SEARCH_SUGGESTIONS = ["Search Toyota", "Search Nissan Sunny", "Search by Brand or Model"];
