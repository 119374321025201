import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import HomeSearchSection from "./component";

// Action
import { reloadCarList } from "../car-listing/actions";

const mapStateToProps = ({
    cities: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName
        } = {}
    }
}) => ({
    selectedCityCode,
    selectedCityName
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeSearchSection);
